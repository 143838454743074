import React from "react";

export const resultatsCardsData = [
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Définir les usages",
    titleWeak: "des toitures-terrasses",
    description:
      "Aujourd’hui, la toiture-terrasse de votre immeuble n’est pas utilisée. Demain, grâce aux travaux, elle pourrait avoir une utilité et cet espace vide pourrait devenir quelque chose qui profite à tous les résidents ! Devenir un lieu de rencontre, un jardin suspendu… Les possibilités sont nombreuses et pour cela nous avons besoin de votre avis !",
    thumbnail: "c1_q1rep1.png",
    imgSrc: "c1_q1rep1.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type d’usage verriez-vous sur les toitures-terrasses ? (N’hésitez pas à donner vos propres options si celles proposées ne vous conviennent pas.)",
        options: [
          {
            label: "La toiture comme une cinquième façade végétalisée.",
            value: "q1rep1",
            results: 18,
            resultsIlotOne: 13,
            resultsIlotTwo: 5,
          },
          {
            label: "Un lieu de détente et de rencontre. ",
            value: "q1rep2",
            results: 7,
            resultsIlotOne: 5,
            resultsIlotTwo: 2,
          },
          {
            label: "Un lieu de culture pour les plantes et les légumes.",
            value: "q1rep3",
            results: 7,
            resultsIlotOne: 7,
            resultsIlotTwo: 0,
          },
          {
            label: "Un jardin suspendu.",
            value: "q1rep4",
            results: 10,
            resultsIlotOne: 8,
            resultsIlotTwo: 2,
          },
        ],
        value: "",
        winner: "q1rep1",
        winnerIlotOne: "q1rep1",
        winnerIlotTwo: "q1rep1",
        totResults: 42,
        totResultsIlotOne: 33,
        totResultsIlotTwo: 9,
      },
    ],
    comments: [
      "J'habite au dernier étage. En été, le plafond devient très chaud, et la température invivable dans l'appartement. Merci de trouver une solution à cette situation.",
      "Une cinquième façade et un jardin suspendu.",
      "Lieu de culture et jardin suspendu",
      "Complexe sportif (Salle de sport + terrain de basket et foot)",
      "et un potager",
      "Ou jardin suspendu",
      "Des panneaux solaire pour économie d'énergie de l'immeuble",
      "Et un lieu de culture et un jardin suspendu.",
      "vos propositions sont intéressantes j'habite depuis 34 ans dans ce logement  en dessous de la terrasse et la dégradation de la sécurité dans le quartier me font hésiter sur le va-et-vient des vendeurs de drogues qui arrivent on ne sait pas comment à rentrer dans l'immeuble donc j'ai très peur qu'un lieu aussi sympathique soit plus  une source de problèmes pour les locataires",
      "Lieu de cultures et jardin suspendu",
      "Rien juste étanchéité et eviter la propagation d insectes types moustiques ",
      "Mais aussi un lieu de culture pour les plantes et les légumes.",
      "Lieu de cultures et jardin pour les besoins des résidents (lieu de partage et d'échanges - lutte contre la précarité et l'isolement)",
      "aucun aménagement!cette partie doit rester sécurisee",
    ],
    answers: [],
    comment: "",
    noticeOne: "* Sous réserve des résultats des études structure",
    noticeTwo: "** Sous condition de création d’un accès indépendant",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "des portes palières",
    description:
      "Votre bailleur souhaite refaire toutes les parties communes et remplacer toutes les portes palières de l’immeuble. Pour cela, votre coopération est requise afin de définir l’ambiance générale, le type de serrurerie ou encore la personnalisation des numéros d’appartement. Ces travaux permettront d’améliorer la sécurité, améliorer la qualité acoustique et le coupe feu de votre résidence.",
    thumbnail: "c2_q1rep1_q2rep1_q3rep2.png",
    imgSrc: "c2_q1rep1_q2rep1_q3rep2.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type d’ambiance souhaiteriez-vous pour les parties communes de l’immeuble ?",
        options: [
          {
            label: "Ambiance pastelle",
            thumbnail: "illustrationAttente.png",
            value: "q1rep1",
            results: 6,
            resultsIlotOne: 6,
            resultsIlotTwo: 0,
          },
          {
            label: "Ambiance énergique",
            thumbnail: "illustrationAttente.png",
            value: "q1rep2",
            results: 3,
            resultsIlotOne: 1,
            resultsIlotTwo: 2,
          },
          {
            label: "Ambiance sobre",
            thumbnail: "illustrationAttente.png",
            value: "q1rep3",
            results: 17,
            resultsIlotOne: 12,
            resultsIlotTwo: 5,
          },
          {
            label: "Ambiance chic",
            thumbnail: "illustrationAttente.png",
            value: "q1rep4",
            results: 13,
            resultsIlotOne: 12,
            resultsIlotTwo: 1,
          },
          {
            label: "Ambiance du sud",
            thumbnail: "illustrationAttente.png",
            value: "q1rep5",
            results: 6,
            resultsIlotOne: 5,
            resultsIlotTwo: 1,
          },
        ],
        value: "q1rep1",
        winner: "q1rep3",
        winnerIlotOne: "q1rep3",
        winnerIlotTwo: "q1rep3",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
      {
        id: "q2",
        type: "radioImage",
        title:
          "Quel type de poignée souhaiteriez-vous pour votre porte d’entrée ?",
        options: [
          {
            label: "Classique",
            thumbnail: "miniatures/c2_q2thumb1.png",
            value: "q2rep1",
            results: 41,
            resultsIlotOne: 33,
            resultsIlotTwo: 8,
          },
          {
            label: "Bouton de tirage central",
            thumbnail: "miniatures/c2_q2thumb2.png",
            value: "q2rep2",
            results: 4,
            resultsIlotOne: 3,
            resultsIlotTwo: 1,
          },
        ],
        value: "q2rep1",
        winner: "q2rep1",
        winnerIlotOne: "q2rep1",
        winnerIlotTwo: "q2rep1",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
      {
        id: "q3",
        type: "radioImage",
        title:
          "Quel type de numérotation d’appartement souhaiteriez-vous sur votre porte palière ?",
        options: [
          {
            label:
              "Votre numéro d’appartement poché (peint directement sur la porte).",
            thumbnail: "miniatures/c2_q3thumb1.png",
            value: "q3rep1",
            results: 12,
            resultsIlotOne: 10,
            resultsIlotTwo: 2,
          },
          {
            label: "Une plaque avec le numéro de votre appartement.",
            thumbnail: "miniatures/c2_q3thumb2.png",
            value: "q3rep2",
            results: 33,
            resultsIlotOne: 26,
            resultsIlotTwo: 7,
          },
        ],
        value: "q3rep1",
        winner: "q3rep2",
        winnerIlotOne: "q3rep2",
        winnerIlotTwo: "q3rep2",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
    ],
    comments: [
      "Concernant les poignées extérieur le modèle que j'ai actuellement me convient",
      "Mettre le numéro sur le côté ",
      "Plaque sobre et discrète",
      "LES PORTES ONT ETE CHANGEES IL N Y A PAS SI LONGTEMPS. LES PORTES MISES POUR MON CAS NE VALAIT LA MIENNE ET EN PLUS ONT ETE MOINS SURES PUISQUE J AI ETE CAMBRIOLEE 2 FOIS",
      "ou ambiance du sud",
      "Les poignets actuelle sont très bien",
      "Plaque sobre et discrète",
      "Sur le côté ",
      "J’espère que le remplacement des portes palières ne se fera pas en fixant de nouveaux châssis dormant sur les châssis actuels. Un tel changement avait été effectué à mon arrivée avec des matériaux de qualité médiocre. Résultat : vol avec effraction : le châssis avait été déformé et la porte ouverte par les cambrioleurs !",
    ],
    answers: [],
    comment: "",
    noticeOne:
      "* Le choix d’aménagement se fera à la majorité des locataires exprimés grâce à cette application.",
  },
  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La révision",
    titleWeak: "ou le remplacement des volets métalliques.",
    description:
      "Les volets métalliques de votre logement sont aujourd’hui victimes de leur grand âge et par voie de conséquence la protection contre le soleil qu’ils assurent dans votre cuisine et votre salon est compromise.",
    thumbnail: "c3_q1rep2.png",
    imgSrc: "c3_q1rep2.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Souhaitez-vous un remplacement de vos volets métalliques dans votre logement ?",
      options: {
        q1rep1: {
          label: "Non, une simple révision suffira",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Souhaitez-vous un remplacement de vos volets métalliques dans votre logement ?",
        options: [
          {
            label: "Non, une simple révision suffira",
            value: "q1rep1",
            results: 8,
            resultsIlotOne: 6,
            resultsIlotTwo: 2,
          },
          {
            label: "Oui",
            value: "q1rep2",
            results: 37,
            resultsIlotOne: 30,
            resultsIlotTwo: 7,
          },
        ],
        value: "",
        winner: "q1rep2",
        winnerIlotOne: "q1rep2",
        winnerIlotTwo: "q1rep2",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
      {
        id: "q2",
        type: "radioImage",
        title:
          "Quel système d’occultation préféreriez-vous pour votre salon et votre cuisine ?",
        options: [
          {
            label: "Store banne",
            thumbnail: "miniatures/c3_q1thumb1.png",
            value: "q2rep1",
            results: 5,
            resultsIlotOne: 3,
            resultsIlotTwo: 2,
          },
          {
            label: "Volet vénitien",
            thumbnail: "miniatures/c3_q1thumb2.png",
            value: "q2rep2",
            results: 32,
            resultsIlotOne: 27,
            resultsIlotTwo: 5,
          },
        ],
        value: "",
        winner: "q2rep2",
        winnerIlotOne: "q2rep2",
        winnerIlotTwo: "q2rep2",
        totResults: 37,
        totResultsIlotOne: 30,
        totResultsIlotTwo: 7,
      },
    ],
    comments: [
      "En mettre à chaque fenêtre pour ceux qui n en ont pas",
      "Il n’y a pas de volés sur mes fenêtres  si toutefois vous en faites métallique c est bien ",
      "volets roulant electrique ou pas prevu avec les fenetres plus isolant l ete comme l hiver",
      "Il n'y en à pas",
      "Nous n'avons pas de volets mais il en faudrait absolument",
      "Je suis dans un studio et je souhaiterais des volets roulants.",
      "Je n'ai pas de volets je souhaite des STORE ROULANT je tiens à préciser que vos propositions de volet vénitien est pas du tout moderne et les stores banne dangereux difficile à manipulé ",
      "Volet électrique coulissant ",
      "Il est indispensable de poser des stores métalliques extérieurs (surtout dans la salon et la chambre) car tout mon appartement est vitré et c.est une vraie serre en été sans parler des problèmes de moustiques.",
      "volets roulant electrique ou manuel",
      "Il est indispensable de poser des stores métalliques extérieurs (surtout dans la salon et la chambre) car tout mon appartement est vitré et c.est une vraie serre en été sans parler des problèmes de moustiques.",
      "volets roulant electrique ou manuel",
      "Nous n'avons pas de volet actuellement mais ce serait souhaitable pur une meileure isolation.",
      "Pas de volets métalliques au 26 allée des Orgues de Flandres",
      "Vivant dans un studio je souhaite l’installation de volet pour limiter la chaleur due à l’exposition au soleil.",
      "volets à plis",
    ],
    answers: [],
    comment: "",
  },
  {
    id: "c4",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "des porte-fenêtres",
    description:
      "Les fenêtres qui composent votre logement et votre immeuble sont un poste important dans le projet de rénovation. Leur type, leur style ou encore leur matériau agiront sur la luminosité de votre logement, son confort thermique ou encore l’aspect esthétique de votre immeuble sur la rue.",
    thumbnail: "c4_q1rep1.png",
    imgSrc: "c4_q1rep1.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Disposez-vous d’une porte-fenêtre dans votre logement ?",
      options: {
        q1rep1: {
          label: "Non",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Disposez-vous d’une porte-fenêtre dans votre logement ?",
        options: [
          {
            label: "Non",
            value: "q1rep1",
            results: 5,
            resultsIlotOne: 3,
            resultsIlotTwo: 2,
          },
          {
            label: "Oui",
            value: "q1rep2",
            results: 40,
            resultsIlotOne: 33,
            resultsIlotTwo: 7,
          },
        ],
        value: "",
        winner: "q1rep2",
        winnerIlotOne: "q1rep2",
        winnerIlotTwo: "q1rep2",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
      {
        id: "q2",
        type: "radio",
        title:
          "Quel système d’ouverture pour votre porte-fenêtre aurait votre préférence ?",
        options: [
          {
            label: "Un système avec double battant.",
            value: "q2rep1",
            results: 35,
            resultsIlotOne: 28,
            resultsIlotTwo: 7,
          },
          {
            label: "Un système à simple battant avec un pan fixe.",
            value: "q2rep2",
            results: 5,
            resultsIlotOne: 5,
            resultsIlotTwo: 0,
          },
        ],
        value: "",
        winner: "q2rep1",
        winnerIlotOne: "q2rep1",
        winnerIlotTwo: "q2rep1",
        totResults: 40,
        totResultsIlotOne: 33,
        totResultsIlotTwo: 7,
      },
    ],
    comments: [
      "Je suis dans un studio et j'ai trois fenêtres: un double battant, un fixe et un double battant. ",
      "AVEC DES BONNE VITRES DE QUALITE ET DOUBLE VITRAGE  celle qu' ont a actuellement sont horrible ",
      "fenetres avec volets roulant  pour plus d;isolation l'hiver et pour maintenir la fraicheur avec les periode caniculaire 'ete",
    ],
    answers: [],
    comment: "",
    noticeOne:
      "* En accord avec les prescriptions architecturales de la Mairie de Paris",
    noticeTwo: "** Cette question ne concerne pas les baies vitrées du salon",
  },
  {
    id: "c5",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "des fenêtres de la cuisine",
    description:
      "Les fenêtres qui composent votre logement et votre immeuble sont un poste important dans le projet de rénovation. Leur type, leur style ou encore leur matériau agiront sur la luminosité de votre logement, son confort thermique ou encore l’aspect esthétique de votre immeuble sur la rue.",
    thumbnail: "c5_q1rep2.png",
    imgSrc: "c5_q1rep2.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Ouvrez-vous la fenêtre dans votre cuisine quand vous cuisinez ?",
      options: {
        q1rep1: {
          label: "Non",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Ouvrez-vous la fenêtre dans votre cuisine quand vous cuisinez ?",
        options: [
          {
            label: "Non",
            value: "q1rep1",
            results: 2,
            resultsIlotOne: 1,
            resultsIlotTwo: 1,
          },
          {
            label: "Oui",
            value: "q1rep2",
            results: 43,
            resultsIlotOne: 35,
            resultsIlotTwo: 8,
          },
        ],
        value: "",
        winner: "q1rep2",
        winnerIlotOne: "q1rep2",
        winnerIlotTwo: "q1rep2",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
      {
        id: "q2",
        type: "radio",
        title:
          "Souhaiteriez-vous que l’on installe une fenêtre oscillant battant ? (Ce système est à un prix supérieur aux autres solutions et il est également plus fragile)",
        options: [
          {
            label: "Oui",
            value: "q2rep1",
            results: 6,
            resultsIlotOne: 4,
            resultsIlotTwo: 2,
          },
          {
            label: "Non",
            value: "q2rep2",
            results: 37,
            resultsIlotOne: 31,
            resultsIlotTwo: 6,
          },
        ],
        value: "",
        winner: "q2rep2",
        winnerIlotOne: "q2rep2",
        winnerIlotTwo: "q2rep2",
        totResults: 43,
        totResultsIlotOne: 35,
        totResultsIlotTwo: 8,
      },
    ],
    comments: [
      "mise en conformité de la plomberie dans la cuisine",
      "Etant dans un studio, je n'ai pas de fenêtres dans ma cuisine. La cuisine est dans l'entrée. ",
      "Suite à des travaux pour vérifier l’amiante, ma peinture de la fenêtre a été abîmée.  Je souhaite une réfection totale de ma cuisine car l’humidité décolle les peintures. Par ailleurs la robinetterie est usagée et le meuble évier de ma cuisine est abîmé. Pour les fenêtres des verres réducteurs de la chaleur seraient un plus indéniable si vous ne posez pas de volet dans les cuisines.",
      "Mettre des fenêtres à isolation phonique.",
    ],
    answers: [],
    comment: "",
    noticeOne:
      "* En accord avec les prescriptions architecturales de la Mairie de Paris.",
  },
  {
    id: "c6",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "du contrôle d’accès",
    description:
      "Le système de contrôle d’accès à votre immeuble sera revu afin de sécuriser les accès mais aussi le rendre plus simple à l’usage. Deux solutions sont proposées par votre bailleur : l’une filaire qui correspond à un interphone classique, l’autre par GSM qui vous permet d’ouvrir l’accès à l’immeuble aux visiteurs grâce à votre téléphone portable.",
    thumbnail: "c6_q1rep1.png",
    imgSrc: "c6_q1rep1.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel système de contrôle d’accès préféreriez-vous ?",
        options: [
          {
            label: "Un contrôle d’accès filaire",
            value: "q1rep1",
            results: 37,
            resultsIlotOne: 30,
            resultsIlotTwo: 7,
          },
          {
            label: "Un contrôle d’accès GSM",
            value: "q1rep2",
            results: 7,
            resultsIlotOne: 5,
            resultsIlotTwo: 2,
          },
        ],
        value: "",
        winner: "q1rep1",
        winnerIlotOne: "q1rep1",
        winnerIlotTwo: "q1rep1",
        totResults: 44,
        totResultsIlotOne: 35,
        totResultsIlotTwo: 9,
      },
    ],
    comments: [
      "Je propose pour la  1er porte ouverture par portable (GSM) seconde portes a 2  avec un code",
      "Pas compris",
      "avec visiophone pour plus de securite",
      "interphones avec vidéo visiophone serait idéal et sécurisant",
      "Pas de préférence vidéo serait bien pour voir qui sonne.",
      "interphone filaire avec visiophone pour plus de securite",
      "avec caméra de visualisation",
    ],
    answers: [],
    comment: "",
  },
  {
    id: "c7",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La réfection de la salle",
    titleWeak: "de bain et des WC",
    description:
      "Une attention toute particulière sera mise dans la rénovation des pièces humides, avec la problématique que tous les locataires n’ont pas sur ce poste les mêmes exigences. En effet, certains ont déjà fait des travaux d’aménagement, d’autres se retrouvent avec des équipements mal adaptés à leur situation ou encore certains locataires ont des exigences très particulières qu’il est difficile de généraliser à tous. C’est pour cela que votre avis est indispensable !",
    thumbnail: "c7_q1rep1_q2rep1.png",
    imgSrc: "c7_q1rep1_q2rep1.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Si vous avez déjà réalisé des travaux dans votre salle de bain ou WC, souhaiteriez-vous conserver vos propres aménagements ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Si vous avez déjà réalisé des travaux dans votre salle de bain ou WC, souhaiteriez-vous conserver vos propres aménagements ?",
        options: [
          {
            label: "Oui",
            value: "q1rep1",
            results: 3,
            resultsIlotOne: 3,
            resultsIlotTwo: 0,
          },
          {
            label: "Non",
            value: "q1rep2",
            results: 42,
            resultsIlotOne: 33,
            resultsIlotTwo: 9,
          },
        ],
        value: "q1rep2",
        winner: "q1rep2",
        winnerIlotOne: "q1rep2",
        winnerIlotTwo: "q1rep2",
        totResults: 45,
        totResultsIlotOne: 36,
        totResultsIlotTwo: 9,
      },
      {
        id: "q2",
        type: "radio",
        title:
          "Pour des raisons de confort, d’accessibilité et en vue d’un gain de place, souhaiteriez-vous remplacer votre baignoire par une douche ?",
        options: [
          {
            label:
              "Oui, je souhaiterais que ma baignoire soit remplacée par une douche.",
            value: "q2rep1",
            results: 31,
            resultsIlotOne: 25,
            resultsIlotTwo: 6,
          },
          {
            label:
              "Non, je souhaiterais garder une baignoire dans ma salle de bain.",
            value: "q2rep2",
            results: 11,
            resultsIlotOne: 8,
            resultsIlotTwo: 3,
          },
          {
            label: "Mon logement ne possède pas de baignoire.",
            value: "q2rep3",
            results: 0,
            resultsIlotOne: 0,
            resultsIlotTwo: 0,
          },
        ],
        value: "q2rep1",
        winner: "q2rep1",
        winnerIlotOne: "q2rep1",
        winnerIlotTwo: "q2rep1",
        totResults: 42,
        totResultsIlotOne: 33,
        totResultsIlotTwo: 9,
      },
      {
        id: "q3",
        type: "radio",
        title:
          "Si vous avez des exigences particulières liées à un usage spécifique de votre salle de bain et / ou de vos WC, souhaitez-vous travailler conjointement avec les équipes d’architectes à son aménagement ?",
        options: [
          {
            label:
              "Oui, j’aimerais collaborer avec les architectes pour l’aménagement des pièces humides de mon logement.",
            value: "q3rep1",
            results: 33,
            resultsIlotOne: 25,
            resultsIlotTwo: 8,
          },
          {
            label: "Non, je ne suis pas intéressé.e",
            value: "q3rep2",
            results: 9,
            resultsIlotOne: 8,
            resultsIlotTwo: 1,
          },
        ],
        value: "q3rep1",
        winner: "q3rep1",
        winnerIlotOne: "q3rep1",
        winnerIlotTwo: "q3rep1",
        totResults: 42,
        totResultsIlotOne: 33,
        totResultsIlotTwo: 9,
      },
    ],
    comments: [
      "Nous souhaitons également un changement de la baignoire et des toilettes qui sont bancales.",
      "je veux une douche à l'italienne svp",
      "Je souhaiterai avoir une évacuation et une alimentation pour machine à laver.",
      "je souhaite le remplacement de ma baignoire ainsi que les carreaux qui entour la baignoire car elle n'est pas au norme d'après le passage d'une personne missionne par 3Fje souhaite également le changement des mes toilettes car très ancienne  et les murs sont fissuré a certains endroit ",
      "Suite à l'intervention de deux plombiers (un envoyé par 3F) les professionnels ont constaté que les robinets d'arrêt de la cuisine et du toilette ainsi que la colonne d'évacuation doivent être impérativement remplacés du fait de leur état de vétusté avancé et des risques qu'ils comportent.",
      "je souhaite garder mon wc  et mon meuble de salle de bain ",
      "Salle de bains oui Scandaleux non à rehabiliter",
      "Si possible, j'aimerai une douche à l'italienne car je suis en fauteuil roulant. Le 22/05/20 mon médecin m'a fait un certificat médical demandant la mise en place d'un évier pour personne handicapée avec la mise en place de mitigeurs pour évier et lavabos. (Je suis amputée de la jambe gauche.) Merci de me lire ",
      "Vu mon âge, j'éprouve des difficultés à enjamber la baignoire, je souhaiterais une douche: c'est bien plus pratique.",
      "Les 3F ont fait des travaux chez moi et en 2018 et 2019 ils m'ont installé une cabine de douche. Superbe et pratique, puis une cuvette de Wc nouvelle plus haute. Donc je n'ai rien à vouloir d'autre.",
      "Tout ma salle de bain est à refaire de À à Z.  Installation d’un éclairage au dessus du lavabo à changer. Ma baignoire est a changer.",
      "Pas de marche pour rentrer dans la douche.",
      "sauf la baignoire que je souhaiterais voir changer pour une douche",
      "Je souhaiterai supprimer le bidet. Les tuyaux de canalisations sont plus ou moins bouchés, dans ma cuisine aussi.",
      "Changement de la baignoire et peinture",
      "Je soussigné Docteur LAURENT QUELET, certifie que l'état de santé de Madame Christiane Ducoin lui impose l'installation d'une douche de plein pied, à la place de la baignoire.",
      "Un système d'aération pour l'humidité",
      "attention particulière sur la puissance du débit de la chasse d'eau des WC (débit actuel trop faible)",
      "Je souhaite qu’une arrivée d’eau ainsi qu’une évacuation soit prévue pour un lave linge",
      "Refaire la peinture dans la salle de bains et des WC.",
      "remplacement du lavabo par 1 colonne et plaque baignoire",
    ],
    answers: [],
    comment: "",
  },
  {
    id: "c8",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La sécurisation",
    titleWeak: "de vos balcons.",
    description:
      "Les balcons de votre immeuble ont besoin d’être sécurisées pour éviter tout incident. Pour cela, les garde-corps des balcons seront remplacés. Au-delà de l’aspect sécuritaire, la nature des nouveaux garde-corps va influer sur la transparence de vos balcons et par conséquent sur la luminosité et la vue à l’intérieur de votre logement.",
    thumbnail: "c8_q1rep3.png",
    imgSrc: "c8_q1rep3.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel type de garde-corps souhaiteriez-vous pour vos balcons ?",
        options: [
          {
            label:
              "Des garde-corps complètement opaques pour qu’on ne puisse pas voir ma terrasse.",
            value: "q1rep1",
            results: 4,
            resultsIlotOne: 3,
            resultsIlotTwo: 1,
          },
          {
            label:
              "Des garde-corps plus transparents pour accroître la luminosité et la vue.",
            value: "q1rep2",
            results: 6,
            resultsIlotOne: 5,
            resultsIlotTwo: 1,
          },
          {
            label: "Un savant mélange des deux propositions.",
            value: "q1rep3",
            results: 11,
            resultsIlotOne: 9,
            resultsIlotTwo: 2,
          },
        ],
        value: "",
        winner: "q1rep3",
        winnerIlotOne: "q1rep3",
        winnerIlotTwo: "q1rep3",
        totResults: 21,
        totResultsIlotOne: 17,
        totResultsIlotTwo: 4,
      },
    ],
    comments: [
      "Pourquoi ne pas fermer les balcons appartements étages élevés côté piscine Mathis, ce serait idéal nous protéger des intempéries ( vent - pluie ) cela nous protégerait du bruit et nous isolerait thermiquement  et nous permetterait dee  vous les économies d’énergie !fermer votre balcon vous protège du bruit et vous isole thermiquement : et nous permettrait de faire des économies d’énergie ",
      "Les balcons ne sont pas tous place au mm endroit dans l immeuble atention",
      "pour choisir plus facilement il aurait ete bien de voir les 3 differentes propositions",
      "Je n ai pas de balcon ",
    ],
    answers: [],
    comment: "",
    noticeOne:
      "* Sous condition d’accord avec les prescriptions architecturales de la Mairie de Paris.",
  },
  {
    id: "c9",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’usage",
    titleWeak: "des locaux communs.",
    description:
      "Les locaux communs doivent être aménagés pour correspondre à vos besoins. C’est ici que vous intervenez : Exprimez vos attentes !",
    thumbnail: "c9_q1rep3.png",
    imgSrc: "c9_q1rep3.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quels sont vos besoins d’aménagement des locaux communs parmi les choix proposés ?",
        options: [
          {
            label: "Un local poussette.",
            value: "q1rep1",
            results: 1,
            resultsIlotOne: 1,
            resultsIlotTwo: 0,
          },
          {
            label: "Un local vélo.",
            value: "q1rep2",
            results: 12,
            resultsIlotOne: 8,
            resultsIlotTwo: 4,
          },
          {
            label: "Les deux.",
            value: "q1rep3",
            results: 18,
            resultsIlotOne: 14,
            resultsIlotTwo: 4,
          },
        ],
        value: "",
        winner: "q1rep3",
        winnerIlotOne: "q1rep3",
        winnerIlotTwo: "q1rep2",
        totResults: 31,
        totResultsIlotOne: 23,
        totResultsIlotTwo: 8,
      },
    ],
    comments: [
      "Pas de local arrondissement pas sécuritaire donc tout locaux seront considérés comme repaire de drogue de wc ou de dortoir a sdf",
      "Surtout pas de locaux poussettes et vélos ",
      "au RDC",
      "Au RDC à l'emplacement actuel.",
      "Surtout pas",
      "A l'intérieur de l'immeuble si possible.",
      "au RDC",
      "Aucun il y aura des vols",
      "Au RDC à coté du local poubelle",
      "Dans un local dans le Hal face à l'ascenseur du 7 Mathis.",
      "Avec  un accès sécurisé ",
      "Aucun",
      "Mais problème de vols ",
      "aucun",
    ],
    answers: [],
    comment: "",
  },
  {
    id: "nc1",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Mise en place",
    titleWeak: "d’une Isolation thermique par l’extérieur",
    description:
      "Cette intervention consiste à poser un manteau de laine minérale sur les façades des bâtiments. Comme un pull, l’isolant va permettre de garder la chaleur dans le bâtiment l’hiver, mais aussi la fraîcheur l’été. Pour poser ce manteau de laine minérale il est nécessaire de retirer la jolie façade en pierre, de mettre l’isolant sur le mur en béton. Le parement pierre pourra être ensuite reposé sur la façade. ",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette intervention consiste à poser un manteau de laine minérale sur
          les façades des bâtiments. Comme un pull, l’isolant va permettre de
          garder la chaleur dans le bâtiment l’hiver, mais aussi la fraîcheur
          l’été. Pour poser ce manteau de laine minérale il est nécessaire de
          retirer la jolie façade en pierre, de mettre l’isolant sur le mur en
          béton. Le parement pierre pourra être ensuite reposé sur la façade.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC1Thumb.png",
    video: {
      url: "https://youtu.be/_faONokkScY",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Ok ",
      "c'est urgent d'assurer une isolation extérieure",
      "Aucune",
      "Vu",
      "Parfait ",
      "Très bonne idée ",
      "oui c'est une bonne idée",
      "il serait judicieux de mettre de l'isolent sur la façade et de mettre une peinture par dessus car  les plaques de marbre sont d'origine et usez et faire des rajouts avec du vieux avec du neufuf ",
      "Important : assurer la pérennité de la protection contre les effets néfastes de l'humidité sur les façades des bâtiments",
      "Mise en place des isolation thermique ",
    ],
    answers: [],
  },
  {
    id: "nc2",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Isolation",
    titleWeak: "et étanchéité de la toiture terrasse",
    description:
      "Cette intervention consiste à enlever ce qu’il y a en toiture (isolant et membrane d’étanchéité) pour poser une couche plus importante d’un nouvel isolant plus performant sur le béton de la toiture puis de venir recouvrir le tout d’une membrane bitumée afin de garantir l’étanchéité de la toiture. Cette intervention va permettre de réaliser des économies de chauffage mais aussi de contrer la descente d’humidité par la toiture dans les logements.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette intervention consiste à enlever ce qu’il y a en toiture (isolant
          et membrane d’étanchéité) pour poser une couche plus importante d’un
          nouvel isolant plus performant sur le béton de la toiture puis de
          venir recouvrir le tout d’une membrane bitumée afin de garantir
          l’étanchéité de la toiture. Cette intervention va permettre de
          réaliser des économies de chauffage mais aussi de contrer la descente
          d’humidité par la toiture dans les logements.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC2Thumb.png",
    video: {
      url: "https://youtu.be/-RSfI0pBTaE",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "C'est urgent de mieux isoler la terrasse",
      "peu importe",
      "Spécificité des travaux à prendre en compte, pour projet utilisation terrasse pour cultures et jardins",
      "Isolation et  étanchéité ",
    ],
    answers: [],
  },
  {
    id: "nc3",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Réfection",
    titleWeak: "de la distribution de chauffage",
    description:
      "Avec le temps, l’acidité de l’eau, et le calcaire, les canalisations peuvent être rongées et se détériorer pouvant occasionner des fuites et une interruption de chauffage dans le pire des cas. Si les diagnostics identifient des détériorations les travaux consisteront à la découpe des réseaux verticaux situés principalement derrière les sanitaires dans les logements puis la pose de tuyaux neufs, l à la même place. Cette intervention permet de préserver un maximum les radiateurs de tout dommage intérieur et de soulager les pompes faisant monter l’eau dans les étages.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Avec le temps, l’acidité de l’eau, et le calcaire, les canalisations
          peuvent être rongées et se détériorer pouvant occasionner des fuites
          et une interruption de chauffage dans le pire des cas. Si les
          diagnostics identifient des détériorations les travaux consisteront à
          la découpe des réseaux verticaux situés principalement derrière les
          sanitaires dans les logements puis la pose de tuyaux neufs, l à la
          même place. Cette intervention permet de préserver un maximum les
          radiateurs de tout dommage intérieur et de soulager les pompes faisant
          monter l’eau dans les étages.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC3Thumb.png",
    video: {
      url: "https://youtu.be/sDG_Qi_KDf8",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Il y a des postes de chauffage qui datent et qui sont probablement bouchés et donc inefficaces",
      "Bonne idée ",
      "L eau est très calcaire et agresse les appareils sans parler des problèmes de peau. Un adoucisseur d’eau serait la solution ainsi que le changement de toutes les robinetteries des cuisines et salle de bain.",
      "Des changements de chauffage serait mieux",
      "les conduites d'alimentations de chauffage et de l'eau courante date des années 1970 je pense que des travaux sont nécessaire ",
      "grande vétusté des canalisations déjà constatée",
      "Réfection de la distribution ",
      "le remplacement des radiateurs serait indispebsable",
    ],
    answers: [],
  },
  {
    id: "nc4",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Traitement ",
    titleWeak: "des menuiseries extérieures",
    description:
      "Cette intervention consiste à enlever les fenêtres en PVC actuelles et le cadre en bois des anciennes menuiseries à pour pouvoir poser les nouvelles fenêtres directement sur le mur et ainsi ne pas créer d’entrée d’air et d’humidité supplémentaire. Cela permettra d’améliorer le confort thermique dans les logements.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette intervention consiste à enlever les fenêtres en PVC actuelles et
          le cadre en bois des anciennes menuiseries à pour pouvoir poser les
          nouvelles fenêtres directement sur le mur et ainsi ne pas créer
          d’entrée d’air et d’humidité supplémentaire. Cela permettra
          d’améliorer le confort thermique dans les logements.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC4Thumb.png",
    video: {
      url: "https://youtu.be/tztfu3vmoqM",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "c'est urgent !",
      "Pensez à mettre une fenêtre avec aération car il y en a pas sur celles de ma chambre. Poser des vitres avec filtre réflecteur pour éviter la chaleur l’été (une serre).",
      "oui",
      "bien c'était nécessaire",
      "Et remplacer les vitres par des vitres à isolation phonique. ",
      "je suis tout à fait d'accord",
    ],
    answers: [],
  },
  {
    id: "nc5",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Amélioration ",
    titleWeak: "de la loge gardien",
    description:
      "La loge gardien sera cloisonnée pour définir plusieurs espaces comme un espace d’attente, un espace de réunion et un point d’info. Le gardien pourra accueillir les locataires dans de bonnes conditions.",
    jsxDescription: (
      <React.Fragment>
        <p>
          La loge gardien sera cloisonnée pour définir plusieurs espaces comme
          un espace d’attente, un espace de réunion et un point d’info. Le
          gardien pourra accueillir les locataires dans de bonnes conditions.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC5Thumb.png",
    video: {
      url: "https://youtu.be/PLjcDJFCI4g",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Question à poser au gardien pas aux locataires",
      "très bien",
      "Pas indispensable ",
      "bien",
      "Bonne idée !",
      "Oui",
    ],
    answers: [],
  },
  {
    id: "nc6",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Amélioration ",
    titleWeak: "de l’accessibilité des parties communes",
    description:
      "Cette opération consiste à changer les ascenseurs pour les moderniser. La cage de l’ascenseur, les portes à chaque étage ainsi que le moteur en toiture vont être enlevés et remplacés. Les nouvelles portes s’ouvriront davantage pour laisser passer un fauteuil roulant sans encombre, les boutons seront à hauteur de tous et le système de sécurité sera renforcé. ",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à changer les ascenseurs pour les moderniser.
          La cage de l’ascenseur, les portes à chaque étage ainsi que le moteur
          en toiture vont être enlevés et remplacés. Les nouvelles portes
          s’ouvriront davantage pour laisser passer un fauteuil roulant sans
          encombre, les boutons seront à hauteur de tous et le système de
          sécurité sera renforcé.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC6Thumb.png",
    video: {
      url: "https://youtu.be/sM731kz6m5A",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "c'est urgent également",
      "Les ascensoristes proposent plusieurs formules j'espère que vous opterez pour la formule 'un remplacement rapide des ascenseurs' afin de ne pas léser les locataires des étages élevés ( personnes âgées, malades, enfants ..)",
      "COMBIEN DE TEMPS LES ASCENSEUR SERONT EN MAINTENANCE ",
      "Un ascenseur au 30 allée des orgues de flandre il est difficile de monter les courses etc.",
      "Quels travaux prévus pour l'amélioration de la propreté et l'aspect des cages d'escalier ?",
      "bien",
    ],
    answers: [],
  },
  {
    id: "nc7",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Isolation",
    titleWeak: "des planchers bas",
    description:
      "Cette opération consiste à projeter un isolant sur les plafonds des caves pour retenir un maximum la chaleur au rez-de-chaussée.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à projeter un isolant sur les plafonds des
          caves pour retenir un maximum la chaleur au rez-de-chaussée.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC7Thumb.png",
    video: {
      url: "https://youtu.be/nB5Q7bsC4hc",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "c'est super !",
      "Ok",
      "Mettre un meilleur éclairage et aération l'atmosphère  est très humide quand je descends à la cave je ne me sens pas en sécurité je descends tjrs avec une personne",
      "oui bonne idée ",
    ],
    answers: [],
  },
  {
    id: "nc8",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Mise en conformité",
    titleWeak: "électrique des logements",
    description:
      "Cette opération consiste à sécuriser et protéger votre installation électrique et vos équipements. Les prises seront démontées et remplacées, des prises pourront être ajoutées dans certaines pièces. Des éléments de protection seront ajoutés ou remplacés dans le tableau électrique pour protéger vos équipements comme le lave-linge, lave vaisselles, micro-onde...",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à sécuriser et protéger votre installation
          électrique et vos équipements. Les prises seront démontées et
          remplacées, des prises pourront être ajoutées dans certaines pièces.
          Des éléments de protection seront ajoutés ou remplacés dans le tableau
          électrique pour protéger vos équipements comme le lave-linge, lave
          vaisselles, micro-onde...
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC8Thumb.png",
    video: {
      url: "https://youtu.be/Ooemv-0ySHg",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Oui pk",
      "super !",
      "Cela a dejà ete fait au alentour de 1999 ?",
      "si cela est possible plus de prises dans chaques pieces ca serait bien ",
      "je suis parfaitement d'accord",
      "bien",
    ],
    answers: [],
  },
  {
    id: "nc9",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Remise du gaz",
    titleWeak: "de cuisson dans les logements",
    description:
      "Cette opération consiste à amener le gaz dans les cuisines des logements où la cuisson est électrique afin d’harmoniser l’ensemble des logements. Un tuyau de gaz sera déployé dans chaque logement via les réservations techniques des cuisines et un robinet sera installé pour la coupure du gaz.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à amener le gaz dans les cuisines des
          logements où la cuisson est électrique afin d’harmoniser l’ensemble
          des logements. Un tuyau de gaz sera déployé dans chaque logement via
          les réservations techniques des cuisines et un robinet sera installé
          pour la coupure du gaz.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC9Thumb.png",
    video: {
      url: "https://youtu.be/_p78HxBgFkM",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Ok",
      "j'ai déjà le gaz",
      "Déjà en place",
      "Dangereux ",
      "Suppression de la cuisson au gaz pour optimisation de la sécurité dans les logements",
      "Facultatif ",
      "j'ai déjà le gaz",
    ],
    answers: [],
  },
  {
    id: "nc10",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Mise en place",
    titleWeak: "d’une VMC Hygro A",
    description:
      "Dans les logements il est nécessaire de ventiler pour éliminer l’humidité, les odeurs de cuisines, de sanitaires par exemple mais aussi pour recharger l’air en oxygène.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Dans les logements il est nécessaire de ventiler pour éliminer
          l’humidité, les odeurs de cuisines, de sanitaires par exemple mais
          aussi pour recharger l’air en oxygène.
        </p>
        <p>
          Sans ventilation mécanique contrôlée, la principale source de
          ventilation est l’ouverture des fenêtres ce qui a pour effet :
        </p>
        <p>
          En hiver de faire entrer de l’air froid dans le logement et qui
          implique donc une surconsommation de chauffage.
        </p>
        <p>
          En été de faire entrer de l’air chaud et ainsi créer de l’inconfort.
        </p>
        <p>
          La Ventilation mécanique Contrôlée (VMC) simple flux aspire l’air
          pollué du logement tout au long de la journée mais en faible quantité.
          Les orifices dans les fenêtres permettent de faire entrer de l’air dit
          « neuf » de l’extérieur pour remplacer l’air pollué qui a été extrait.
        </p>
        <p>
          La VMC Hygroréglable est une amélioration de la VMC standard et permet
          d'augmenter l'évacuation de l’air pollué quand les pièces de service
          (cuisines et salles de bain) sont utilisées, et de la diminuer lorsque
          l'air est sec.{" "}
        </p>
        <p>
          Pour le bâtiment K les travaux vont consister à installer des moteurs
          en toiture et des gaines (gros tuyaux) dans les réservations
          techniques des logements. Les murs des réservations techniques seront
          percés pour pouvoir raccorder les gaines aux bouches de ventilation.{" "}
        </p>
        <p>
          Pour les deux autres bâtiments (30-32 allée des orgues de Flandre et 7
          -9 Rue Mathis) les moteurs de la ventilation en toiture seront changés
          ainsi que l’ensemble des bouches dans les logements.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC10Thumb.png",
    video: {
      url: "https://youtu.be/xd7Dil93BGY",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Au 26 vmc inexcistant",
      "OK",
      "bien",
      "IMPORTANT : A prévoir : Nettoyage et entretien réguliers de cette VMC par le bailleur pour la sécurité sanitaire des locataires",
      "Oui",
    ],
    answers: [],
  },
];
