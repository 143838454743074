import React from "react";
import classes from "./ResultsConcertableLayout.module.css";
// Components
import ResultsConcertableQuestion from "../ConcertableUtils/resultsConcertableQuestion";
// MUI
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { IconButton } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
// MUI 2
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";

const ResultsConcertableLayout = ({
  ilot,
  setIlot,
  card,
  dispatch,
  width,
  currentUser,
  setCommentsModalShow,
}) => {
  const questions = card.questions;

  const handleChange = (event, questionID, cardID, questionType) => {
    // Update the answers value for the given question
    dispatch({
      type: "UPDATE_ANSWER",
      cardID,
      questionID,
      answer: event.target.value,
    });

    // Update the cards imageURL - should be run after the answer has been updated
    if (questionType !== "input") {
      dispatch({
        type: "UPDATE_IMAGE",
        cardID,
      });
    }
  };

  const handleSubmit = () => {
    let answers = {};
    questions.map((question) => {
      if (question.type === "input") {
        answers[question.id] = {
          question: question.title,
          value: question.value,
        };
      } else {
        answers[question.id] = {
          question: question.title,
          answer:
            question.options[
              question.options.findIndex(
                (option) => option.value === question.value
              )
            ].label,
          value: question.value,
        };
      }
    });
    answers["comment"] = card.comment;
    uploadAnswer(currentUser.uid, card.id, answers, dispatch);
  };

  // Header =============================================================================================
  const header = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>{card.titleStrong}</h3>
        <h4>{card.titleWeak}</h4>
      </div>
      <div className={classes.CloseIcon}>
        <IconButton
          onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
        >
          <CancelRoundedIcon style={{ color: "black", fontSize: 30 }} />
        </IconButton>
      </div>
    </header>
  );

  // Image Preview =============================================================================================
  const imgSrc = questions[0].type === "input" ? card.thumbnail : card.imgSrc;
  const imagePreview = (
    <div className={classes.ImagePreview}>
      <img
        style={{ width: "100%" }}
        alt="salon"
        src={require(`../../../../assets/travaux/${imgSrc}`)}
      />
    </div>
  );

  // Description  =============================================================================================
  const description = (
    <div className={classes.Description}>
      <div className={classes.DescriptionTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            🦺
          </span>{" "}
          Nature des travaux :
        </p>
      </div>
      <div className={classes.DescriptionContent}>
        <p>{card.description}</p>
      </div>
    </div>
  );

  // Dropdown Ilots
  const dropdownIlots = (
    <div className={classes.DropdownIlots}>
      <FormControl className={classes.formControl} style={{ minWidth: 220 }}>
        <InputLabel
          id="select-ilot"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Lots
        </InputLabel>
        <Select
          labelId="select-ilot"
          id="simple-select-ilot"
          value={ilot}
          onChange={(e) => setIlot(e.target.value)}
          color="secondary"
          autoWidth={false}
        >
          <MenuItem value={10}>Tous les lots</MenuItem>
          <MenuItem value={20}>R335L</MenuItem>
          <MenuItem value={30}>3702L</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  // Conditional
  // const conditional = card.conditional.isConditional ? (
  //   <ConcertableQuestion
  //     question={card.conditional}
  //     cardID={card.id}
  //     handleChange={dispatch}
  //   />
  // ) : null;

  // Questions  =============================================================================================
  const questionsElement = (
    <React.Fragment>
      {questions.map((question) => {
        return (
          <ResultsConcertableQuestion
            ilot={ilot}
            question={question}
            key={question.id}
            cardID={card.id}
            handleChange={handleChange}
          />
        );
      })}
    </React.Fragment>
  );
  // const questionsElement = !card.conditional.isConditional ? (
  //   <React.Fragment>
  //     {questions.map((question) => {
  //       return (
  //         <ConcertableQuestion
  //           key={question.id}
  //           question={question}
  //           cardID={card.id}
  //           handleChange={handleChange}
  //         />
  //       );
  //     })}
  //   </React.Fragment>
  // ) : card.conditional.value ? (
  //   <React.Fragment>
  //     {questions.map((question) => {
  //       return (
  //         <ConcertableQuestion
  //           key={question.id}
  //           question={question}
  //           cardID={card.id}
  //           handleChange={handleChange}
  //         />
  //       );
  //     })}
  //   </React.Fragment>
  // ) : null;

  // Commentaires ===============================================================================================
  const commentaires = (
    <div className={classes.CommentSection}>
      <h3>Commentaires :</h3>
      {card.comments.map((comment, index) => {
        return (
          <div className={classes.CommentGroup} key={index}>
            <div className={classes.Comment}>
              <h3>#{index + 1}</h3>
              <p>{comment}</p>
            </div>
            {card.answers[index] !== "NA" &&
            card.answers[index] !== undefined ? (
              <p className={classes.CommentAnswer}>
                <strong>Réponse :</strong> {card.answers[index]}
              </p>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  // Commentaire + Valider  =============================================================================================
  const commentValider = (
    <div className={classes.Button}>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        style={{ width: "10em" }}
        onClick={() => {
          dispatch({ type: "SET_HIDECARD" });
        }}
      >
        Retour
      </Button>
    </div>
  );

  const notices = (
    <div className={classes.Notices}>
      <p>{card.noticeOne}</p>
      <p>{card.noticeTwo}</p>
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 1400) {
    return (
      <div className={classes.Card}>
        {header}
        {imagePreview}
        <div className={classes.CardContent}>
          {description}
          <div className={classes.QuestionsHeader}>
            <h3>Résultats</h3>
          </div>
          {dropdownIlots}
          {questionsElement}

          {commentaires}
          {notices}
          {commentValider}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.Card}>
        <div className={classes.LeftSection}>
          {header}
          {imagePreview}
          <div className={classes.CardContent}>{description}</div>
        </div>
        <div className={classes.RightSection}>
          <div className={classes.CardContent}>
            <div className={classes.QuestionsHeader}>
              <h3>Résultats</h3>
            </div>
            {dropdownIlots}
            {questionsElement}
            <div className={classes.RightFooter}>{commentaires}</div>
            {notices}
          </div>
        </div>
      </div>
    );
  }
};

export default ResultsConcertableLayout;
