import React, { useContext } from "react";
import classes from "./ContactForm.module.css";
// MUI
// import MailRoundedIcon from "@material-ui/icons/MailRounded";
import { TextField, Button } from "@material-ui/core";
// Context
import { ContactContext } from "../../../contexts/ContactContext";
// Utils
import { uploadMessage } from "../../../utils/ContactFormUtils";
import { checkValidity } from "../../../utils/LoginUtils";

const ContactForm = () => {
  const { contactData, dispatch } = useContext(ContactContext);
  const { message, email, helperText } = contactData;

  let helperMessage = null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.valid && message.valid) {
      uploadMessage(email.value, message.value, dispatch);
    } else {
      dispatch({
        type: "SET_ERROR",
        helperType: "error",
        helperMessage: "Veuillez renseigner un email / message valide.",
      });
    }
  };

  helperMessage =
    helperText.type === "success" ? (
      <p className={classes.Success}>{helperText.message}</p>
    ) : (
      <p className={classes.Error}>{helperText.message}</p>
    );

  return (
    <div className={classes.ContactForm}>
      <div className={classes.ContactFormTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            📫
          </span>{" "}
          Contacter les équipes d'Immobilière 3F
        </p>
      </div>
      <form className={classes.ContactFormFields} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          label="Votre email"
          style={{ marginBottom: "0.8em" }}
          color="secondary"
          value={email.value}
          onChange={(e) =>
            dispatch({
              type: "SET_EMAIL",
              email: e.target.value,
              valid: checkValidity(e.target.value, email.validation),
            })
          }
        />
        <TextField
          variant="outlined"
          label="Votre message"
          multiline
          rows={5}
          style={{ marginBottom: "1em" }}
          color="secondary"
          value={message.value}
          onChange={(e) =>
            dispatch({
              type: "SET_MESSAGE",
              message: e.target.value,
              valid: checkValidity(e.target.value, message.validation),
            })
          }
        />
        {helperMessage}
        <div className={classes.Button}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            style={{ width: "7em" }}
            onClick={handleSubmit}
          >
            Envoyer
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
