import React from "react";
import classes from "./Home.module.css";
// Images
// import BonHomme from "../../assets/images/bonhomme.png";
// Primary Components
// import DescriptionSection from "../../components/HomePage/DescriptionSection/DescriptionSection";
import Blog from "../Blog/Blog";
import ContactForm from "../../components/Travaux/ContactForm/ContactForm";
import Footer from "../../components/Travaux/Footer/Footer";
// UI Componentns
import Separator from "../../components/UI/Separator/Separator";
// import LongButton from "../../components/UI/Buttons/LongButton/LongButton";
// Layout
import Layout from "../../hoc/Layout/Layout";
// MUI
import { Button } from "@material-ui/core";
// Youtube
import ReactPlayer from "react-player";

const Home = (props) => {
  const heroImage = require(`../../assets/homePage/hero.png`);
  const illustrationAttente = require(`../../assets/homePage/illustrationAttente.png`);
  const calendarBlock = require(`../../assets/homePage/calendarBlock.svg`);
  const carouselOne = require(`../../assets/homePage/bati_1.png`);
  const carouselTwo = require(`../../assets/homePage/bati_2.png`);
  const carouselThree = require(`../../assets/homePage/bati_3.png`);
  const ParticiperHandler = () => {
    props.history.push("/travaux");
  };
  const blogPostHandler = () => {
    props.history.push("/blog/1");
  };

  return (
    <Layout>
      {/* <div className={classes.HeaderImage}>
        <img style={{ width: "100%" }} alt="header" src={illustrationAttente} />
      </div> */}
      <div className={classes.HomeCardBackground}>
        <div className={classes.HomeCardContent}>
          <div className={classes.HeroImage}>
            <img alt="Planning" src={heroImage} />
          </div>

          <div className={classes.Section}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👪
              </span>
              <h3>Un projet en concertation avec les locataires</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                La réhabilitation de votre résidence s’inscrit dans un projet
                plus global, le Projet d’Intérêt Régional des Orgues de
                Flandres. Il s’agit d’un quartier ciblé par l’Agence National du
                Renouvellement Urbain (ANRU) pour pouvoir mener des réflexions
                fortes quant au bon fonctionnement du quartier après travaux.
              </p>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Le groupe Immobilière 3F, signataire de la convention ANRU,
                s’est engagée auprès de l’état à réhabiliter votre résidence.
                Immobilière 3F s’est donné les principaux objectifs suivants :
              </p>
            </div>

            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  L’augmentation de la performance énergétique pour obtenir le
                  label BBC Rénovation. A travers ce label, nous avons une
                  double ambition :
                </strong>
                <br />
                <br />
                1. La réduction du montant de vos charges en menant une action
                forte sur l’isolation du bâtiment (façade, toiture, fenêtre
                etc.) .
                <br />
                <br />
                2. Lutter contre le réchauffement climatique en limitant les
                déperditions énergétiques des trois bâtiments.
              </p>
            </div>
            <br />
            <br />
            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  Limiter les déchets de matériaux liés à la réhabilitation afin
                  de réduire notre empreinte carbone. Pour cela, le groupe 3F et
                  la société Cycle Up œuvrent ensemble pour favoriser le
                  réemploi des matériaux. Nous pouvons réemployer les matériaux
                  de différentes manières :
                </strong>
                <br />
                <br />
                1. Réemploi In situ : On réutilise les matériaux sur site.
                <br />
                <br />
                2. Réemploi Ex situ : On exporte les matériaux pour un autre
                chantier de réhabilitation.
              </p>
            </div>
            <br />
            <br />
            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  Impliquer les locataires dans le projet de réhabilitation.
                  Réhabiliter une résidence, ce n’est pas seulement une
                  stratégie patrimoniale du groupe 3F. C’est avant tout, une
                  volonté d’amélioration de votre confort. C’est également une
                  satisfaction de tous pour un projet global malgré les
                  nuisances que le chantier va obligatoirement générer. Pour
                  travailler à la réussite de ce projet, nous œuvrons pour que
                  chacun d’entre vous puisse s’impliquer.
                </strong>
                <br />
                <br />
                La mise en place de l’application par notre partenaire 1001 rues
                va nous permettre de définir des besoins et d’orienter des choix
                que nous pourrions faire ensemble.
              </p>
            </div>

            <div className={classes.YoutubePlayer}>
              <ReactPlayer
                url="https://youtu.be/iAOvtANpQeQ"
                loop={true}
                width={207}
                height={448}
                muted={true}
              />
            </div>

            <div className={classes.HighlightNote}>
              <span></span>
              <p>À vos marques, prêts, participer !</p>
            </div>
            <div className={classes.ParticiperButton}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={ParticiperHandler}
              >
                je participe
              </Button>
            </div>
          </div>

          <div
            className={classes.Section}
            style={{ marginTop: "2em", marginLeft: "0", marginRight: "0" }}
          >
            <div className={classes.CarouselSection}>
              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${carouselOne})`,
                  backgroundPosition: "right",
                }}
              ></div>
              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${carouselTwo})`,
                  backgroundPosition: "right",
                }}
              ></div>

              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${carouselThree})`,
                  backgroundPosition: "right",
                }}
              ></div>
            </div>
          </div>

          <div className={classes.Section}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👪
              </span>
              <h3>Un projet en concertation avec les locataires</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Pour mener à bien ce projet ambitieux de réhabilitation 3F a
                décidé de mettre les locataires à contribution dans une démarche
                de concertation inédite.
              </p>
            </div>

            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                Durant les mois à venir, vous pourrez choisir et personnaliser
                certains travaux prévus dans le projet de réhabilitation grâce à{" "}
                <strong>l’application corehab</strong>. Cette application vous
                permettra par ailleurs de vous renseigner sur l’ensemble des
                travaux qui auront lieux dans votre logement ou dans votre
                résidence. Pour commencer, cliquez sur je participe juste en
                dessous.
              </p>
            </div>

            <div className={classes.ParticiperButton}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={ParticiperHandler}
              >
                je participe
              </Button>
            </div>

            <div className={classes.HighlightNote}>
              <span></span>
              <p>
                Plus que jamais, votre avis compte et nous vous attondons
                nombreux sur la plateforme carremathis.corehab.fr !
              </p>
            </div>
          </div>

          <Separator />

          {/* <div className={classes.Section} style={{ marginTop: "1.5em" }}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                📰
              </span>
              <h3>Les dernières informations</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Vous trouverez ici toutes les dernières actualités liées au
                projet et à la concertation locataires.
              </p>
            </div>

            <Blog blogPostHandler={blogPostHandler} />
          </div> */}
          <div className={classes.Section} style={{ marginTop: "1.5em" }}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                📅
              </span>
              <h3 style={{ fontSize: "1.2rem" }}>
                Les grandes étapes prévisionnelles du projet de réhabilitation
                et de réaménagements des espaces extérieurs
              </h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Trouvez ici toutes les étapes du projet de réhabilitation. Nous
                mettrons à jour régulièrement ce calendrier pour mieux vous
                informer.
              </p>
            </div>

            <div className={classes.GrandesEtapes}>
              <img alt="Planning" src={calendarBlock} />
            </div>
          </div>
          <Separator />
          <div className={classes.ContactForm}>
            <ContactForm />
          </div>
        </div>
      </div>

      <div className={classes.Footer}>
        <Footer />
      </div>
    </Layout>
  );
};

export default Home;
